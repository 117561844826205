import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Baglessday2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Baglessday/12.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1.5,
            cols: 1,
        },


    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Bagless Day
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6-8     Date: : 30 October 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur recently celebrated the culmination of "Bagless Day" activities for Classes 6 to 8, an event crafted to inspire creativity, practical skills and broadened perspectives beyond conventional academics. Class 6 captivated their audience with a skit and PowerPoint presentation on "Various Climatic Zones," allowing them to explore and creatively depict diverse climates around the world while honing their presentation and teamwork skills.
                                    <br></br>
                                    Class 7 showcased their ingenuity through “creative advertisements”, encouraging persuasive communication and critical thinking as students developed engaging ads on various topics. Class 8 took on the theme of "Empowering Women," presenting meaningful reforms and ideas to promote gender equality, sparking thoughtful discussions on inclusivity and social awareness.
                                    <br></br>
                                    Students responded with enthusiasm, enjoying the experiential approach to learning. Principal reviewed the presentation of all the classes of “Bagless Days” and shared her valuable feedback to enrich the participants with her insights and appreciated the effort, creativity, teamwork and confidence of all participants.
                                    <br></br>
                                    She also acknowledged the guidance of faculty and support of parents that made the event successful. The event successfully reinforced the school’s commitment to holistic education, blending creativity with skill-building activities that prepare students for both academic and real-world experiences.
                                    <br></br>
                                    <br></br>

                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    Culmination Day celebrates our journey, growth, learning, bringing together every effort and shared creativity.
                                    <br />

                                </Typography>
                            </Box>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Baglessday blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Baglessday2024;